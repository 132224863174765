import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Container,
  Button
} from 'reactstrap';

const Cookies = () => {
  const [isAccepted, setIsAccepted] = useState(true);

  useEffect(() => {
    const visitCookie = getCookie('visit');
    if (!visitCookie) {
      setIsAccepted(false);
    }
  }, []);

  const setCookie = (name,value,days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    setIsAccepted(true);
  }

  const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  return (
    <>
      {isAccepted ? null : (
        <Container className="cookies-container py-3">
          <Row>
            <Col>
              We use cookies for better performance
            </Col>
            <Col>
              <Button
                className="btn btn-round"
                color="primary"
                onClick={() => {
                  setCookie('visit', true, 1825);
                }}
              >
                Accept
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default Cookies;