import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';

import axios from 'axios';

function GiftCard({ card, ...props }) {
  const [img, setImg] = useState(card?.card_image_url);

  useEffect(() => {
    setImg(card?.card_image_url);
  }, [card]);

  useEffect(() => {
    // correct some of GT's errors.
    const imgUrl = card?.card_image_url;
    const imgUrlArray = imgUrl.split('.');
    if (imgUrlArray[imgUrlArray.length - 1] === 'pngg') {
      setImg(imgUrl.slice(0, imgUrl.length - 1));
    }
  }, []);

  return (
    <>
      <Link
        to={'card/' + card?.code}
        target="_blank" rel="noopener noreferrer"
        className="card-link-element"
      >
        <p
          className='card-price-tag'
        >
          {card?.denomination_type === 'open' ? (
            `from ${+(card?.minimum_value)} ${card?.currency_code}`
          ) : (
            `${+(card?.minimum_value)} ${card?.currency_code}`
          )}
        </p>
        <img
          alt="..."
          className="img-raised gift-card-image"
          src={img || 'https://crm.globetopper.com/brandImages/61086a9b755fc.png'}
        />
      </Link>
      <p className="text-reset text-decoration-none mt-4">
        <span className={`mr-2 card-flag-fi fi fi-${card?.countries[0]?.toLowerCase()} fis`}></span>
        {card?.name}
      </p>
    </>
  );
}

function CardsGallery(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fetchedData, setFetchedData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [toggleGalleryView, setToggleGalleryView] = useState(false);

  const getData = (currentIndex) => {
    if (!props.clientCountryCode) return;
    axios.post(process.env.REACT_APP_HOSTNAME + '/api/client/products/search', {
      limit: 16,
      offset: Math.round(16 * currentIndex),
      searchString: props.searchString,
      countryCode: props.clientCountryCode
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + process.env.REACT_APP_TOKEN
      }
    })
      .then((data) => {
        if (!isSearching) {
          setFetchedData(prevData => {
            return [...prevData, data?.data?.data?.products ]
          });
        } else {
          setSearchData(prevData => {
            return [...prevData, data?.data?.data?.products ]
          });
        }
        setCurrentIndex(prevIndex => prevIndex + 1);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
        setIsButtonLoading(false);
      });
  }

  useEffect(() => {
    if (props.searchString.length) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    setToggleGalleryView(prevState => !prevState);
  }, [props.searchString])

  useEffect(() => {
    setFetchedData([]);
    setSearchData([]);
    setCurrentIndex(0);
    getData(0);
  }, [toggleGalleryView, props.clientCountryCode])

  const loadMoreCards = () => {
    setIsButtonLoading(true);
    getData(currentIndex);
  };

  return (
    <>
      <div>
        <Container className="pl-0 pr-0">
          {isLoading && (
            <Row>
              <Col md="12" className="text-center mt-5">
                <h2 className="mb-0">
                  <i className="now-ui-icons loader_refresh spin"></i>
                </h2>
              </Col>
            </Row>
          )}
          {isSearching && !isLoading && searchData[0]?.length > 0 && searchData.map(v => (
            <>
              {v.length ? (
                <Row>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[0] && <GiftCard card={v[0]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[1] && <GiftCard card={v[1]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[2] && <GiftCard card={v[2]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[3] && <GiftCard card={v[3]} />}</Col>
                </Row>
              ) : (<div></div>)}
              {v.length > 4 && (
                <Row>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[4] && <GiftCard card={v[4]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[5] && <GiftCard card={v[5]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[6] && <GiftCard card={v[6]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[7] && <GiftCard card={v[7]} />}</Col>
                </Row>
              )}
              {v.length > 8 && (
                <Row>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[8] && <GiftCard card={v[8]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[9] && <GiftCard card={v[9]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[10] && <GiftCard card={v[10]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[11] && <GiftCard card={v[11]} />}</Col>
                </Row>
              )}
              {v.length > 12 && (
                <Row>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[12] && <GiftCard card={v[12]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[13] && <GiftCard card={v[13]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[14] && <GiftCard card={v[14]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[15] && <GiftCard card={v[15]} />}</Col>
                </Row>
              )}
            </>
          ))}
          {!isSearching && !isLoading && fetchedData[0]?.length > 0 && fetchedData.map(v => (
            <>
              {v.length ? (
                <Row>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[0] && <GiftCard card={v[0]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[1] && <GiftCard card={v[1]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[2] && <GiftCard card={v[2]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[3] && <GiftCard card={v[3]} />}</Col>
                </Row>
              ) : (<div></div>)}
              {v.length > 4 && (
                <Row>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[4] && <GiftCard card={v[4]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[5] && <GiftCard card={v[5]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[6] && <GiftCard card={v[6]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[7] && <GiftCard card={v[7]} />}</Col>
                </Row>
              )}
              {v.length > 8 && (
                <Row>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[8] && <GiftCard card={v[8]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[9] && <GiftCard card={v[9]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[10] && <GiftCard card={v[10]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[11] && <GiftCard card={v[11]} />}</Col>
                </Row>
              )}
              {v.length > 12 && (
                <Row>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[12] && <GiftCard card={v[12]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[13] && <GiftCard card={v[13]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[14] && <GiftCard card={v[14]} />}</Col>
                  <Col sm="12" md="6" lg="3" className="text-center mt-5">{v[15] && <GiftCard card={v[15]} />}</Col>
                </Row>
              )}
            </>
          ))}
          {!isLoading && ((!isSearching && !fetchedData[0]?.length) || (isSearching && !searchData[0]?.length)) && (
            <h3 className="mt-5 text-center">No products found</h3>
          )}
          <Row className="mt-5">
            <Col md="12" className="text-center">
              <Button 
                className="btn-round font-weight-bold btn"
                color="primary"
                type="button"
                onClick={loadMoreCards}
              >
                Load more...
                {isButtonLoading && <i className="now-ui-icons loader_refresh spin ml-2"></i>}
                {/* <img className="mr-2" src={IconGiftcard}></img> */}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CardsGallery;