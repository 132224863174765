import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  Modal,
  Label,
  Form,
  Input,
  InputGroup,
  Spinner,
  Toast,
  ModalBody,
  Badge
} from 'reactstrap';

let timer;

const CheckoutModal = (props) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState(1200);
  const [displayTime, setDisplayTime] = useState('20:00');
  const [coingateOrderData, setCoingateOrderData] = useState();
  const [showToast, setShowToast] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);

  const handleClose = () => {
    setShow(false);
    stopTimer();
  };
  const handleShow = () => {
    if (!props.validateEmail()) return;
    setShow(true);
  };
  const navigate = useNavigate();

  const startTimer = () => {
    timer = setInterval(() => {
      countdown();
    }, 1000);
  };
  const countdown = () => {
    if (timeLeft === 1) clearInterval(timer);
    setTimeLeft(prevTime => {
      changeDisplayTime(prevTime);
      return prevTime - 1;
    });
  }
  const changeDisplayTime = (timeLeft) => {
    let hours = Math.floor(timeLeft / (60 * 60));

    let divisor_for_minutes = timeLeft % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    if (seconds < 10) seconds = '0' + seconds;

    let time = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };

    setDisplayTime(`${time.m}:${time.s}`);
  };
  const stopTimer = () => {
    clearInterval(timer);
    setTimeLeft(1200);
    setDisplayTime('20:00');
  };

  const copyToClipboard = (e) => {
    const value = e.currentTarget.previousSibling.value;
    navigator.clipboard.writeText(value);
    setShowToast(true);
  };

  const goHome = () => {
    navigate('/');
  };

  useEffect(() => {
    if (!show) return;
    setIsLoading(true);
    axios.post(process.env.REACT_APP_HOSTNAME + '/api/payments/coingate/order/create', {
      price: props.paymentPrice,
      currency: props.paymentCurrency,
      initialCurrency: props.initialCurrencyCode,
      deliveryEmail: props.deliveryEmail,
      productCode: props.productCode,
      quantity: props.quantity,
      amount: props.amount,
      productName: props.productName,
      networkName: props.networkName
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + process.env.REACT_APP_TOKEN
      }
    })
      .then((data) => {
        setCoingateOrderData(data?.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
        startTimer();
      });
  }, [show])

  return (
    <>
      <Button
        className="btn-primary btn-round font-weight-bold btn d-flex align-self-center mr-auto ml-auto align-items-center justify-content-center"
        id="button-pay"
        onClick={handleShow}
      >
        PROCEED TO PAY {props.paymentPrice} {props.paymentCurrency}
      </Button>

      <Modal className="checkout-modal" isOpen={show} toggle={handleClose}>
        <div className="modal-header justify-content-center flex-nowrap">
          <button
            className="close"
            type="button"
            onClick={handleClose}
          >
            <i className="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <h4 className="pt-0 title title-up">Pay with crypto</h4>
        </div>
        <Badge color="primary" className="timer-badge">
          {displayTime}
        </Badge>
        <ModalBody style={{overflowY: 'auto'}}>
          {isLoading ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <h2 className="mb-0">
                <i className="now-ui-icons loader_refresh spin"></i>
              </h2>
            </div>
          ) : (
            <div>
              <Label className="giftcard-disclaimer">
                <svg style={{position: 'relative', top: '-2px', marginRight: 5}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF3C78" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </svg>
                Payment instructions were sent to <span className="font-weight-bold">{props.deliveryEmail}</span>
                <br></br>
                <br></br>
                Gift card will be sent to your email immediately after payment is processed!
              </Label>

              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  margin: '10px 0'
                }}
              >
                <Button
                  className="btn-primary btn font-weight-bold btn-round"
                  id="button-return-to-all"
                  onClick={goHome}
                >
                  Done. Continue browsing gift cards
                </Button>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default CheckoutModal;
