/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// core components

function DefaultFooter() {
  return (
    <>
      <footer className="footer footer-default">
        <Container>
          <Row>
            <Col sm="12" md="4" className="mb-5">GCNHUB</Col>
            <Col sm="12" md="4" className="mb-5">
              <Row>
                <Col><h5 className="font-weight-bold">Info</h5></Col>
              </Row>
              <Row>
                <Col>
                  <a
                    href={'/contact-us'}
                  >
                    <p>Contact</p>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col sm="12" md="4" className="mb-5">
              <Row>
                <Col><h5 className="font-weight-bold">Legal</h5></Col>
              </Row>
              <Row>
                <Col>
                  <a
                    href={'/terms-and-conditions'}
                  >
                    <p>Terms & Conditions</p>
                  </a>
                  <a
                    href={'/privacy-policy'}
                  >
                    <p>Privacy Policy</p>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              Copyright © {new Date().getFullYear()}, GCNhub. All rights reserved
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
