/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
// core components

import HeroImage from "../../assets/img/Hero_image.svg";
import IconGiftcard from "../../assets/img/icon_giftcard.svg";
import IconCurrencies from "../../assets/img/icon_currencies.svg";

function IndexHeader() {
  let pageHeader = React.createRef();

  return (
    <>
      <div className="page-header">
        <Container>
          <Row>
            <Col lg="5" className="text-left align-self-center">
              <h1 className="h1-seo font-weight-bold">Get your awesome Giftcards</h1>
              <h4 className="mb-4">
                We offer the best deals in our shop.
                Check them out now. We have
                awesome stuff on sale for you.
              </h4>
              <Button
                className="btn-round font-weight-bold btn-cta"
                color="primary" type="button"
                onClick={() => {
                    const cards = document.querySelector('#cardsStart');
                    cards.scrollIntoView({behavior: 'smooth'});   
                }}
              >
                <img className="mr-2" src={IconGiftcard}></img>
                Buy a giftcard
              </Button>
              <h5 className="mt-4">
                We Accept
              </h5>
              <img src={IconCurrencies}></img>
            </Col>
            <Col lg="7" className="justify-content-center">
              <img src={HeroImage}></img>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
