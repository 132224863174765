import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
  Badge
} from "reactstrap";
import { Link, Outlet, useParams } from "react-router-dom";

import axios from 'axios';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import PriceConatainer from "components/GiftCards/PriceConatainer";
import CheckoutModal from "components/GiftCards/CheckoutModal";

const COM_RATE = 0.1;

const CardInfo = ({...props}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [areCurrenciesLoading, setAreCurrenciesLoading] = useState(true);
  const [productData, setProductData] = useState();
  const { productCode } = useParams();
  const [img, setImg] = useState();

  const [isCalculatingPrice, setIsCalculatingPrice] = useState(true);
  const [currencies, setCurrencies] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState('BTC');
  const [selectedDenomination, setSelectedDenomination] = useState();
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState({});
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [networkName, setNetworkName] = useState('bitcoin');
  const [isInvalidInputMax, setIsInvalidInputMax] = useState(false);
  const [isInvalidInputMin, setIsInvalidInputMin] = useState(false);

  useEffect(() => {
    // loading specific card data
    axios.get(process.env.REACT_APP_HOSTNAME + '/api/client/products/info/' + productCode, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + process.env.REACT_APP_TOKEN
        }
      })
        .then((data) => {
          // console.log(data?.data?.data?.products[0])
          // setProductData(data?.data?.data?.products[0]);
          
          // BIG TODO!!! REMOVE COMMAS FROM MIN/MAX VALUES IN DATABASE
          let dataObj = data?.data?.data?.products[0];
          dataObj.minimum_value = dataObj.minimum_value.replace(',', '');
          dataObj.maximum_value = dataObj.maximum_value.replace(',', '');
          setProductData(dataObj);
          setSelectedDenomination(dataObj.minimum_value);
          // BIG TODO!!! REMOVE COMMAS FROM MIN/MAX VALUES IN DATABASE
          
          // correct some of GT's errors.
          const imgUrl = data?.data?.data?.products[0].card_image_url;
          const imgUrlArray = imgUrl.split('.');
          if (imgUrlArray[imgUrlArray.length - 1] === 'pngg') {
            setImg(imgUrl.slice(0, imgUrl.length - 1));
          } else {
            setImg(imgUrl);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    // fetching available currencies
    axios.get(process.env.REACT_APP_HOSTNAME + '/api/payments/coingate/currencies', {
      headers: {
        'Authorization': 'Bearer ' + process.env.REACT_APP_TOKEN,
        'Content-Type': 'application/json'
      }
    })
      .then((data) => {
        setCurrencies(data.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setAreCurrenciesLoading(false));
  }, []);

  const calculatePrice = async () => {
    const exchangeRateData = await axios(`${process.env.REACT_APP_HOSTNAME}/api/payments/coingate/rate/${productData.currency_code}/${selectedCurrency}`, {
      headers: {
        'Authorization': 'Bearer ' + process.env.REACT_APP_TOKEN,
        'Content-Type': 'application/json'
      }
    });
    const exchangeRate = exchangeRateData.data;
    return {
      price: ((1 + COM_RATE) * selectedDenomination * exchangeRate).toPrecision(6),
      total: ((1 + COM_RATE) * selectedDenomination * exchangeRate * quantity).toPrecision(6)
    };
  };

  useEffect(() => {
    if (!productData) return;
    setIsCalculatingPrice(true);
    // default currency is BTC, fetching exchange rate and calculating price
    calculatePrice()
      .then(price => {
        setPrice(price)
      })
      .catch(err => console.log(err))
      .finally(setIsCalculatingPrice(false));
  }, [selectedDenomination, quantity, selectedCurrency])

  const selectDenomination = (e) => {
    e.currentTarget.closest('.checkout-denominations-list-container').querySelector('.active').classList.remove('active');
    e.currentTarget.classList.add('active');
    setSelectedDenomination(e.currentTarget.getAttribute('denomination'));
  };

  const selectDenominationFromRange = (e) => {
    if (/^(?:[1-9]\d*|\d)$/.test(+e.target.value)) {
      setIsInvalidInputMax(false);
      setIsInvalidInputMin(false);
      setSelectedDenomination((+e.target.value).toFixed(2));
      if (+e.target.value > productData.maximum_value) {
        setIsInvalidInputMax(true);
      }
      if (+e.target.value < productData.minimum_value) {
        setIsInvalidInputMin(true);
      }
    }
    // if (/^(?:[1-9]\d*|\d)$/.test(+e.target.value) &&
    //     +e.target.value > productData.maximum_value) {
    //   setSelectedDenomination((+productData.maximum_value).toFixed(2));
    // }
    // if (/^(?:[1-9]\d*|\d)$/.test(+e.target.value) &&
    //     +e.target.value < productData.minimum_value) {
    //   setSelectedDenomination((+productData.minimum_value).toFixed(2));
    // }
  }

  const onSelectCurrency = (currencySymbol, currencyName) => {
    setSelectedCurrency(currencySymbol);
    setNetworkName(currencyName.toLowerCase().replace(' ', ''));
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(prev => prev - 1);
    }
  };
  const increaseQuantity = () => {
    if (quantity < 10) {
      setQuantity(prev => prev + 1);
    }
  };

  const validateEmail = () => {
    if (isInvalidInputMax || isInvalidInputMin) return;
    const form = document.querySelector('#emailForm');
    const isValid = form.checkValidity();
    if (isValid) {
      setEmailInvalid(false);
      return true;
    } else {
      setEmailInvalid(true);
      // scrollToValidationMessage();
      return false;
    }
  };

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="main card-info-container">
          {!isLoading && (
            <Container>
              <Row>
                <Col md="12">
                  <h2 className="font-weight-bold">
                    <span className={`mr-2 card-flag-fi fi fi-${productData.countries[0]?.toLowerCase()} fis`}></span>
                    {productData.name}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col md="4" sm="12" className="text-center">
                  <img
                    src={img || 'https://crm.globetopper.com/brandImages/61086a9b755fc.png'}
                    className="mb-5 img-raised gift-card-image"
                    alt=""
                  />
                </Col>
                <Col md="8" sm="12">
                  {productData.denomination_type === 'fixed' && (
                    <Row className="align-items-center mb-4">
                      <Col>
                        <p className="mb-0">Denomination</p>
                      </Col>
                      <Col className="text-right">
                        <Badge color="primary" className="mb-0 denomination-badge">
                          <p className="mb-0 font-weight-bold">
                            {+(productData.minimum_value) + ' ' + productData.currency_code}
                          </p>
                        </Badge>  
                      </Col>
                    </Row>
                  )}

                  {productData.denomination_type === 'open' && (
                    <Row className="align-items-center mb-4">
                      <Col className="pr-0">
                        <p className="mb-0">Denomination</p>
                      </Col>
                      <Col className="text-right pl-0">
                        <InputGroup className="denomination-container-input mb-0">
                          <Input
                            className="gcnhub-range-input"
                            aria-label="Example text with button addon"
                            aria-describedby="basic-addon1"
                            value={+selectedDenomination}
                            min={+productData.minimum_value}
                            max={+productData.maximum_value}
                            onChange={selectDenominationFromRange}
                            invalid={isInvalidInputMax || isInvalidInputMin}
                          />
                          <InputGroupAddon
                            addonType="append"
                          >
                            <InputGroupText>
                              <p>
                                {productData.currency_code}
                              </p>
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormFeedback tooltip type="invalid" className="text-left">
                            {isInvalidInputMax && (
                              <>
                                Max {(+productData.maximum_value).toFixed(0)} {productData.currency_code}
                              </>
                            )}
                            {isInvalidInputMin && (
                              <>
                                Min {(+productData.minimum_value).toFixed(0)} {productData.currency_code}
                              </>
                            )}
                          </FormFeedback>
                        </InputGroup>
                      </Col>
                    </Row>
                  )}

                  <Row className="align-items-center mb-4">
                    <Col className="pr-0">
                      <p className="mb-0">Pay with</p>
                    </Col>
                    <Col className="pl-0 text-right">
                      {!areCurrenciesLoading && (
                        <PriceConatainer
                          currencies={currencies}
                          isCalculatingPrice={!areCurrenciesLoading && isCalculatingPrice}
                          price={price}
                          selectedCurrency={selectedCurrency}
                          onSelectCurrency={onSelectCurrency}
                        />
                      )}
                    </Col>
                  </Row>

                  <Row className="align-items-center mb-4">
                    <Col>
                      <p className="mb-0">Quantity</p>
                    </Col>
                    <Col className="justify-content-end d-flex align-items-center">
                      <Button
                        className="quantity-btn btn-round font-weight-bold btn"
                        color="primary"
                        id="button-decrease-quantity-fixed"
                        onClick={decreaseQuantity}
                      >
                        -
                      </Button>
                      <Input
                        aria-label="Example text with button addon"
                        aria-describedby="basic-addon1"
                        value={quantity}
                        max={10}
                        min={1}
                        disabled
                        className="quantity-input font-weight-bold"
                      />
                      <Button
                        className="quantity-btn btn-round font-weight-bold btn"
                        color="primary"
                        id="button-increase-quantity-fixed"
                        onClick={increaseQuantity}
                      >
                        +
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form
                        noValidate
                        id="emailForm"
                      >
                        <FormGroup  className="mb-3" controlId="exampleForm.ControlInput1">
                          <InputGroup hasValidation>
                            <Input
                              className="email-input"
                              type="email"
                              placeholder="name@example.com"
                              onChange={e => setEmail(e.target.value)}
                              required
                              invalid={emailInvalid}
                            />
                            <FormFeedback tooltip id="emailInvalidMessage" type="invalid">
                              Please enter a valid email address
                            </FormFeedback>
                          </InputGroup>
                        </FormGroup>
                      </Form>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <CheckoutModal
                        paymentPrice={price.total}
                        paymentCurrency={selectedCurrency}
                        initialCurrencyCode={productData.currency_code}
                        deliveryEmail={email}
                        productCode={productData.code}
                        quantity={quantity}
                        amount={selectedDenomination}
                        validateEmail={validateEmail}
                        productName={productData.name}
                        networkName={networkName}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  {productData.redeem_instructions_html && (
                    <>
                      <h4>Description</h4>
                      <p>{productData.description}</p>
                    </>
                  )}                  
                  {productData.redeem_instructions_html && (
                    <>
                      <h4>How to redeem</h4>
                      <p>{productData.redeem_instructions_html}</p>
                    </>
                  )}
                  {productData.terms_and_conditions_html && (
                    <>
                      <h4>Terms and conditions</h4>
                      <p>{productData.terms_and_conditions_html}</p>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          )}
          <Outlet />
        </div>
        <DefaultFooter />
      </div>
    </>
  )
};

export default CardInfo;