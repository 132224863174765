import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// styles for this kit
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss?v=1.5.0";
import "./assets/demo/demo.css?v=1.5.0";
import "./assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "./views/Index.js";
import CardInfo from "./views/CardInfo";
import PrivacyPolicy from "views/PrivacyPolicy";
import TermsAndConditions from "views/TermsAndConditions";
import TermsOfSale from "views/TermsOfSale";
import Cookies from "views/Cookies";
import ContactUs from "views/ContactUs";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter basename="/">
    <Routes>
      <Route
        path="/"
        element={<Index />}
      />
      <Route
        path="/card/:productCode"
        element={<CardInfo />}>
      </Route>
      <Route
        path="/terms-and-conditions"
        element={<TermsAndConditions />}
      />
      <Route
        path="/privacy-policy"
        element={<PrivacyPolicy />}
      />
      <Route
        path="/contact-us"
        element={<ContactUs />}
      />
    </Routes>

    <Cookies />
  </BrowserRouter>
);
