import { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Spinner,
  Badge,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';

const PriceConatainer = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const selectCurrency = (e) => {
    props.onSelectCurrency(e.currentTarget.querySelector('.modal-currency-symbol').innerText,
                          e.currentTarget.querySelector('.modal-currency-name').innerText);
    handleClose();
  }

  return (
    <>
      <InputGroup className="price-container-input mb-0">
        <Input
          disabled
          value={!props.isCalculatingPrice && props.price.price}
          color="primary"
          className="font-weight-bold"
        />
        <InputGroupAddon
          addonType="append"
          onClick={handleShow}
        >
          <InputGroupText>
            <p>
              {props.selectedCurrency}
            </p>
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>

      <Modal className="currencies-modal" isOpen={show} toggle={handleClose}>
        <div className="modal-header justify-content-center flex-nowrap">
          <button
            className="close"
            type="button"
            onClick={handleClose}
          >
            <i className="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <h4 className="pt-0 title title-up">Select currency</h4>
        </div>
        <ModalBody>
          {props.currencies.map((val) => (
            <div
              className="modal-currency-container"
              key={'currency_selector_item_key_' + val.symbol}
              onClick={selectCurrency}
            >
              <span className="modal-currency-symbol">{val.symbol}</span>
              <span className="modal-currency-name">{val.title}</span>
            </div>
          ))}
        </ModalBody>
      </Modal>
    </>
  );
}

export default PriceConatainer;
