import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from 'reactstrap';

import axios from 'axios';

const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');
  const [isSent, setIsSent] = useState(false);

  const navigate = useNavigate();

  const sendEmail = () => {
    if (!validateEmail()) return;

    const payload = {
      firstName,
      lastName,
      email,
      message
    };

    axios.post(process.env.REACT_APP_HOSTNAME + '/api/client/ticket/create',
      payload,
      {
      headers: {
        'Authorization': 'Bearer ' + process.env.REACT_APP_TOKEN,
        'Content-Type': 'application/json'
      }
    })
      .catch((err) => console.log(err))
      .finally(() => setIsSent(true));
    console.log(payload)
  };

  const validateEmail = () => {
    const form = document.querySelector('#contactUsEmail');
    const isValid = form.checkValidity();
    if (isValid) {
      setEmailInvalid(false);
      return true;
    } else {
      setEmailInvalid(true);
      // scrollToValidationMessage();
      return false;
    }
  };

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="main">
          <Container className="contact-us-container">
            <Row>
              <Col sm="0" md="3"></Col>
              <Col>
                <h1 className="text-center">Contact Us</h1>
                
                {isSent ? (
                  <>
                    <p>
                      Thank you!
                    </p>
                    <p>
                      We will contact you soon at <span className="font-weight-bold">{email}</span>
                    </p>
                    <Button
                      className="btn btn-round w-100 font-weight-bold"
                      color="primary"
                      onClick={() => {
                        navigate('/');
                      }}
                    >
                      Home
                    </Button>
                  </>
                ) : (
                  <>
                    <Form
                      noValidate
                      id="contactUsFirstName"
                    >
                      <FormGroup className="mb-3" controlId="exampleForm.ControlInput1">
                        <InputGroup hasValidation>
                          <Input
                            className="email-input"
                            placeholder="First name"
                            onChange={e => setFirstName(e.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Form>

                    <Form
                      noValidate
                      id="contactUsLastName"
                    >
                      <FormGroup className="mb-3" controlId="exampleForm.ControlInput1">
                        <InputGroup hasValidation>
                          <Input
                            className="email-input"
                            placeholder="Last name"
                            onChange={e => setLastName(e.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Form>

                    <Form
                      noValidate
                      id="contactUsEmail"
                    >
                      <FormGroup className="mb-3" controlId="exampleForm.ControlInput1">
                        <InputGroup hasValidation>
                          <Input
                            className="email-input"
                            type="email"
                            placeholder="* name@example.com"
                            onChange={e => setEmail(e.target.value)}
                            required
                            invalid={emailInvalid}
                          />
                          <FormFeedback tooltip id="emailInvalidMessage" type="invalid">
                            Please enter a valid email address
                          </FormFeedback>
                        </InputGroup>
                      </FormGroup>
                    </Form>

                    <Form
                      noValidate
                      id="contactUsMessage"
                    >
                      <FormGroup className="mb-3" controlId="exampleForm.ControlInput1">
                        <InputGroup hasValidation>
                          <Input
                            className="email-input"
                            type="textarea"
                            rows="5"
                            placeholder="Write a message"
                            onChange={e => setMessage(e.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Form>

                    <Button
                      className="btn btn-round w-100 font-weight-bold"
                      color="primary"
                      onClick={() => {
                        sendEmail();
                      }}
                    >
                      Submit
                    </Button>
                  </>
                )}
              </Col>
              <Col sm="0" md="3"></Col>
            </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
};

export default ContactUs;