import React, { useState, useEffect } from "react";

import axios from 'axios';

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle
} from "reactstrap";

// core components
import CardsGallery from "../../components/GiftCards/CardsGallery";

import Countries from '../../utils/countries.json';

function GiftCards() {
  const [leftFocus, setLeftFocus] = React.useState(false);
  const [rightFocus, setRightFocus] = React.useState(false);
  const [clientCountryCode, setClientCountryCode] = useState('');
  const [searchString, setSearchString] = useState('');
  const [countriesArray, setCountriesArray] = useState(Countries);
  
  useEffect(() => {
    axios.get('https://extreme-ip-lookup.com/json/?key=ylGRmUa4ilbXDLs4Y0BR')
      .then((data) => {
        setClientCountryCode(data?.data?.countryCode);
      })
      .catch((err) => console.log(err));
  }, []);

  const searchForCountry = (e) => {
    e.preventDefault();
    const inputVal = e.target.value.toLowerCase();
    setCountriesArray(Countries.filter(v => v.en_short_name.toLowerCase().indexOf(inputVal) !== -1));
  }

  return (
    <>
      <div className="section section-basic" id="basic-elements">
        <Container>
          <Row>
            <Col md="12">
              <h4 className="font-weight-bold text-center">
                Shop from Hundreds of Gift Cards
              </h4>
              <p className="text-center">
                Buy, Send & Claim Gift Cards. Chip in with Friends. Store & Manage Gift Cards.
              </p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg="6" sm="12">
              <InputGroup className={rightFocus ? "input-group-focus" : ""}>
                <Input
                  id="brand-search-input"
                  placeholder="Search for Brands or products"
                  type="text"
                  onFocus={() => setRightFocus(true)}
                  onBlur={() => setRightFocus(false)}
                ></Input>
                <InputGroupAddon
                  addonType="append"
                  onClick={() => setSearchString(document.querySelector('#brand-search-input').value)}
                  style={{cursor: 'pointer'}}
                >
                  <InputGroupText>
                    <i
                      className="now-ui-icons ui-1_zoom-bold"
                    ></i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg="6" sm="12">
              <UncontrolledDropdown id="country_dropdown">
                <DropdownToggle
                  caret
                  data-toggle="dropdown"
                  // href=""
                  id="navbarDropdown"
                  tag="a"
                >
                  <InputGroup className="">
                    <Input
                      id="country-search-input"
                      placeholder={Countries.find(v => v.alpha_2_code === clientCountryCode)?.en_short_name || ''}
                      type="text"
                      onChange={searchForCountry}
                      // value={Countries.find(v => v.alpha_2_code === clientCountryCode)?.en_short_name || ''}
                    ></Input>
                  </InputGroup>
                </DropdownToggle>
                <DropdownMenu
                  onClick={(e) => {
                    e.preventDefault();
                    setClientCountryCode(e.target.value);
                    document.getElementById('country-search-input').value = Countries.find(v => v.alpha_2_code === e.target.value)?.en_short_name;
                  }}
                >
                  {countriesArray.map(v => (
                    <DropdownItem value={v.alpha_2_code}>{v.en_short_name}</DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          
          <CardsGallery
            searchString={searchString}
            clientCountryCode={clientCountryCode}
          />
        </Container>
      </div>
    </>
  );
}

export default GiftCards;
