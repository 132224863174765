import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import {
  Container,
  Row,
  Col
} from 'reactstrap';

const PrivacyPolicy = () => {
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="main">
          <Container>
            <Row>
              <Col>
                <h1>Privacy Policy</h1>
                <p>GCN hub is committed to protecting and respecting your privacy. This policy (together with our terms of use set out above and any other documents referred to in it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By entering, connecting to, accessing, or using the Service, you acknowledge that you are accepting and consenting to the practices described in this policy. If you disagree with any term provided herein, you may not use the Service.</p>
                <p>Please note that you are not obligated to provide us with Personal Information (as defined below) by law. You agree and acknowledge that you provide us with Personal Information voluntarily, so that we may provide you with this Service. You agree that the Company may store Personal Information in a database within the terms of this policy.</p>
                <p>For the purpose of the Data Protection Act 1998 (the “Act”), the data controller is GCN HUB LTD (Company No. 516004686), with its registered offices at Rakel ve Shmuel idelson 5, Tel Aviv, Israel its nominated representative is GCN HUB UK LTD of 15 Alexandra Corniche, Hythe, Kent, United Kingdom, CT21 5RW.</p>
                <h5>INFORMATION WE MAY COLLECT FROM YOU</h5>
                <p>The Company collects two types of information:</p>
                <p>The first type of information the Company collects is anonymous information that does not recognize individuals or can be linked to a specific User (“Non-Personal Information”). The Company is not aware of the identity of the User from which it collected such personal information. Non-Personal Information is any public information, which is available to the Company as Users make use of the App and/or Site. Non-Personal Information collected by the Company, including technical information and information about User behaviour, and may include, among other things, the identity of the operating system, screen resolution, the type and version of the device, the User’s mobile provider, browser type, and the length of time User is on the Service and so on. </p>
                <p>The second type of information is individually identifiable information (“Personal Information”). This information may identify an individual or may be of a private and/or sensitive nature such as User IP address and geographical location of the User, mainly for enhancing the User’s experience and for geo-location and security purposes as further detailed below and any independently User-provided personal details. Each User provides GCN hub with the User’s mobile device number and the details of the country of residence for purposes of receiving the Service, such that the Gift Vouchers may be delivered to the Buyers (or such designated Recipients). Each User must provide GCN hub certain Personal Information, including without limitation User’s valid credit card information including credit card holder location, BIN number, credit card type and brand and credit card number, in order to complete an online payment transaction for purposes of purchasing any Gift Voucher via the Service, as well as any additional requested information for purposes of identity verification, fraud detection and for the aforementioned payment purposes. Each User may choose to either register for an Account via a third party site (i.e. Facebook or Google Plus) or may choose to register via the Service directly for which the User must provide their full name and electronic mail address. In the event that User chooses to have a Gift Voucher delivered to a desired recipient they must provide the desired recipient’s full name, electronic mail address and/or delivery address (if applicable).</p>
                <p>PLEASE NOTE: USER IS RESPONSIBLE TO OBTAIN, MAINTAIN AND HAVE RECEIVED INFORMED CONSENT FROM ANY OF USER’S DESIRED RECIPIENTS OF A GIFT VOUCHER TO SUPPLY GCN HUB WITH IDENTIFYING INFORMATION OF THE DESIRED RECIPIENT FOR PURPOSES OF DELIVERING THE GIFT VOUCHER.</p>
                <h5>HOW WE COLLECT INFORMATION</h5>
                <p>We may collect and process information by the following main methods: </p>
                <p>Information you give us (Voluntarily Provided). You may give us Personal Information about you by filling in forms on our Service or by corresponding with us by phone, e-mail or otherwise. This includes information you provide when you register or login to use our Service, search for a product, place an order, upload or view Content via the Service and when you report a problem with our Service. We may store the Personal Information and Non-Personal Information either independently or through our authorized third-party service providers as detailed below.</p>
                <p> Information we collect about you (Use of the Service). With regard to your access and use of our Service we, either independently or through our authorized third-party service providers as detailed below, automatically collect and store certain Personal and Non-Personal Information, including when you make an online payment via the Service.</p>
                <h5>COOKIES</h5>
                <p>Our Site uses cookies to distinguish you from other Users of our Site. This helps us to provide you with a good experience when you browse our Site and also allows us to improve our Site.</p>
                <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer\'s hard drive.</p>
                <p>We use the following cookies:</p>
                <p>Strictly necessary cookies. These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website or make use of e-billing services.</p>
                <p>Analytical/performance cookies. They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</p>
                <p>Functionality cookies. These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</p>
                <p>Targeting cookies. These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and any advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</p>
                <p>Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>
                <p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. Please note that if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our Site.</p>
                <p>Except for essential cookies, all cookies will expire after five (5) years.</p>
                <h5>USES MADE OF THE INFORMATION</h5>
                <p>We use information held about you in the following ways:</p>
                <p>to carry out our obligations arising from any contracts entered into between you and us and to provide you with the information, products and services that you request from us, including without limitation enabling the Seller to supply products to the Buyer and to process payments for successful orders;</p>
                <p>to contact you and pursue enquiries and/or complaints, including governmental agencies’ requests, in the event that you are in default of any contract you enter into directly with other users of our site;</p>
                <p>to provide you with information about other goods and services we offer that are similar to those that you have already purchased or enquired about (but you may stop receiving this at any time by contacting us) and to otherwise personalize the content provided to you;</p>
                <p>to comply with any applicable law or regulation;</p>
                <p>to reduce the risk of fraudulent online payment transactions;</p>
                <p>to notify you about changes to our Service;</p>
                <p>to ensure that content from our Service is presented in the most effective manner for you and for further customization and improvement of the Service’s accuracy and interface.</p>
                <p>to administer our Service and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</p>
                <p>to allow you to participate in interactive features of our service, when you choose to do so;</p>
                <p>as part of our efforts to keep our Service safe and secure;</p>
                <p>to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you; and to make suggestions and recommendations to you and other Users about goods or services that may interest you or them.</p>
                <h5>DISCLOSURE OF YOUR INFORMATION</h5>
                <p>We may share your personal information with any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006.</p>
                <p>We may share your information with selected third parties including:</p>
                <p>Business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you.</p>
                <p>Advertisers and advertising networks that require the data to select and serve relevant adverts to you and others. We do not disclose information about identifiable individuals to our advertisers, but we may provide them with aggregate information about our Users. We may also use such aggregate information to help advertisers reach the kind of audience they want to target. We may make use of the personal data we have collected from you to enable us to comply with our advertisers\' wishes by displaying their advertisement to that target audience.</p>
                <p>Credit card and payment processors in order to process your payments for receipt of the products;</p>
                <p>Analytics and search engine providers that assist us in the improvement and optimisation of our site.</p>
                <p>Credit reference, identify verification, fraud and security service agencies for the purpose of assessing your credit score and identity.</p>
                <p>We may disclose your information to third parties for the following purposes:</p>
                <p>If GCN hub is undergoing a change of control, including by means of a merger, acquisition or purchase of all or substantially all of its assets, in which case personal data held by it about its Users will be one of the transferred assets and may also be shared with prospective buyers or sellers.</p>
                <p>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply these Terms; or to detect, prevent or otherwise address fraud, security or technical issues; or to protect the rights, property, or safety of GCN hub, our employees, customers or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.</p>
                <p>With your explicit approval prior to disclosure.</p>
                <p>To cooperate with third parties for the purpose of enhancing the User’s experience.</p>
                <p>For avoidance of doubt, GCN hub may transfer and disclose or otherwise use Non-Personal Information or information which is linked to anonymous random identifiers or information that is aggregated in a non-identifiable way, at its own discretion.</p>
                <h5>WHERE WE STORE YOUR PERSONAL DATA</h5>
                <p>GCN hub may process and store your data in a country outside the European Economic Area ("EEA"). Your data will also be processed by staff operating outside the EEA engaged in, among other things, the management of your online account, the processing of any payments and the provision of support services. By submitting your personal data, you agree to this use of your data.</p>
                <p>All information you provide to us is stored on our secure servers or the secure servers of our payment processor or other third party providers. Any payment transactions will be encrypted using SSL technology. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Service, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</p>
                <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our Service; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
                <h5>RETENTION OF DATA</h5>
                <p>We will not keep your personal data for longer than is necessary for the purpose for which it is held or otherwise processed by us. This means that data will be destroyed or erased from our systems when it is no longer required.</p>
                <h5>YOUR RIGHTS</h5>
                <p>You have the right to ask us not to process your personal data for marketing purposes. You can exercise your right to prevent such processing by checking certain boxes on the forms we use to collect your data. You can also exercise the right at any time by contacting us at  support@card-wow.com</p>
                <p>Our Service may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.</p>
                <h5>ACCESS TO INFORMATION</h5>
                <p>The Act gives you the right to access information held about you. Your right of access can be exercised in accordance with the Act. Any access request may be subject to a fee of £10 to meet our costs in providing you with details of the information we hold about you.</p>
                <h5>CHANGES TO OUR PRIVACY POLICY</h5>
                <p>GCN hub reserves the right to change this policy at any time, so please re-visit this page frequently. In the event of any substantial change of this policy, we will make reasonable efforts to post a clear notice on the Site. Such substantial changes will take effect seven (7) days after such notice was provided on our Services or sent by email. Otherwise, all other changes to this Privacy Policy are effective as of the stated “Last Revised” date and your continued use of the Services after the Last Revised date will constitute acceptance of, and agreement to be bound by, those changes.</p>
                <h5>CONTACT</h5>
                <p>Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to admin@gcnhub.com  or The Mill, Pury Hill Business Park, Alderton Road, Towcester, United Kingdom, NN12 7LS.</p>
              </Col>
            </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
};

export default PrivacyPolicy;